




























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class SuccessfullyCreatedNotice extends Vue {
  @Prop(String) registeredRecipient!: string

  clickRegisterOther (): void {
    this.$emit('clickRegisterOther')
  }

  onClickComplete (): void {
    this.$emit('onClickComplete')
  }
}
