










































































import Vue from 'vue'
import Component from 'vue-class-component'
import RecipientDownload from '@/views/recipient/RecipientDownload.vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import RegisterIndividualRecipientModal from '@/components/recipient/RegisterIndividualRecipientModal.vue'
import SuccessfullyCreatedNotice from '@/components/recipient/SuccessfullyCreatedNotice.vue'
import CountryEnum from '@/enums/CountryEnum'
import { Mutation } from 'vuex-class'
import {
  IUploadRecipientPresentation,
  UploadRecipientPresentation
} from '@/presentation/recipient/UploadRecipientPresentation'
import { ParsedRecipient } from '@/presentation/recipient/model/ParsedRecipient'

@Component({
  components: {
    RecipientDownload,
    BasicDialog,
    RegisterIndividualRecipientModal,
    SuccessfullyCreatedNotice
  }
})
export default class RegisterSelector extends Vue {
  @Mutation setRegisterRecipients: any
  @Mutation disableLoading: any
  presentation: IUploadRecipientPresentation = new UploadRecipientPresentation()
  individualRecipientModal: boolean = false
  recipientCreated: boolean = false
  registeredRecipient: string = ''
  selectedWarningCountries: Array<keyof typeof CountryEnum> = []
  activeCollapseName: Array<string> = []
  recipientDownloadCollapseName: string = 'download'

  get modalTitle(): string {
    return `<span class="title-beside-division"> ${this.$t(
      'commons.recipient'
    )}</span>  <span class="title-division"></span> <span class="title-beside-division">${this.$t(
      'menu.recipients_register_one'
    )}</span>`
  }

  get isActiveDownload(): boolean {
    return this.activeCollapseName.includes(this.recipientDownloadCollapseName)
  }

  openManualEnlist(): void {
    this.individualRecipientModal = !this.individualRecipientModal
  }

  recipientSuccessfullyCreated(name: string): void {
    this.recipientCreated = true
    this.registeredRecipient = name
  }

  closeModal(): void {
    this.individualRecipientModal = !this.individualRecipientModal
    this.recipientCreated = false
  }

  async uploadMultipleSheet(files: any): Promise<void> {
    const invalidFile = !(files && files.raw)
    if (invalidFile) return
    const file: Blob = files.raw
    const parsedRecipient: Dictionary<Array<ParsedRecipient>> = await this.presentation.getParsedRecipient(file)
    if (!(await this.presentation.isValidExcel())) {
      this.disableLoading()
      await this.presentation.showValidationMessage()
      return
    }
    this.setRegisterRecipients(parsedRecipient)
    await this.$router.push('invalidRegister')
  }
}
