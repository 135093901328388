







































































































import Vue from 'vue'
import Component from 'vue-class-component'
import {
  DownloadRecipientPresentation,
  IDownloadRecipientPresentation
} from '@/presentation/recipient/DownloadRecipientPresentation'
import { IOtherCountry } from '@/components/recipient/model/IOtherCountry'
import { CountryEnum } from '@/enums'

@Component
export default class RecipientDownload extends Vue {
  isReady = false
  presentation: IDownloadRecipientPresentation = new DownloadRecipientPresentation()
  checkedAll: boolean = false
  timeout = 0
  warningNoticeCountriesObject: Dictionary<boolean> = {
    US: false
  }
  destroyPopoverDelaySec = 2

  isEmptySelectedCountry (): boolean {
    return !(this.presentation.selectedCountries.length || this.presentation.selectedOtherCountries.length)
  }

  getCountryLabel (countryIso: string) {
    return this.$t(`country.${[countryIso.toLowerCase()]}`)
  }

  isNoticeCountry (countryIso: string): boolean {
    return Object.keys(this.warningNoticeCountriesObject).includes(countryIso as keyof typeof CountryEnum)
  }

  destroyNotice (country: string) {
    this.warningNoticeCountriesObject[country] = false
  }

  handleChecked (countryIso: string): void {
    const isChecked = this.presentation.isSelectedCountry(countryIso as keyof typeof CountryEnum)
    const isCheckedAll: boolean = this.presentation.selectedCountries.length === this.presentation.receivableCountriesIso.length
    this.checkedAll = isChecked && isCheckedAll
    const isWarningCountrySelected = this.warningNoticeCountriesObject.hasOwnProperty(countryIso)
    if (!isWarningCountrySelected) return
    if (isChecked) {
      this.warningNoticeCountriesObject[countryIso] = true
      return
    }
    this.warningNoticeCountriesObject[countryIso] = false
  }

  handleCheckedAll (): void {
    const isCheckedAllAlready: boolean = this.presentation.selectedCountries.length === this.presentation.receivableCountriesIso.length
    if (isCheckedAllAlready) {
      this.checkedAll = false
      this.presentation.selectedCountries = []
      Object.keys(this.warningNoticeCountriesObject).forEach(country => {
        this.warningNoticeCountriesObject[country] = false
      })
    } else {
      this.checkedAll = true
      this.presentation.selectedCountries = this.presentation.receivableCountriesIso
      Object.keys(this.warningNoticeCountriesObject).forEach(country => {
        this.warningNoticeCountriesObject[country] = true
      })
    }
  }

  querySearchAsync (queryString: string, callback: any) {
    const links = this.presentation.otherCountrySuggestions
    const results = queryString
      ? links.filter(link => {
        return link.value.toLowerCase().includes(queryString.toLowerCase())
      }) : links
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      callback(results)
    }, 300 * Math.random())
  }

  onSelectOtherCountry (item: IOtherCountry): void {
    this.presentation.onSelectOtherCountry(item)
  }

  onDeCheckedOtherCountry (): void {
    this.presentation.onDeCheckedOtherCountry()
  }

  async onClickDownload (): Promise<void> {
    await this.presentation.download()
  }

  async created () {
    this.isReady = await this.presentation.initialize()
  }
}
