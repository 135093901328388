import { render, staticRenderFns } from "./RegisterIndividualRecipientModal.vue?vue&type=template&id=47c78212&"
import script from "./RegisterIndividualRecipientModal.vue?vue&type=script&lang=ts&"
export * from "./RegisterIndividualRecipientModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports